import * as React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import './TextGraphTwoCol.scss';
import DefaultImage from '../../../assets/images/history.png';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

let classNames = require('classnames');

const TextGraphTwoCol = ({
  title,
  text,
  textList,
  graphDatas,
  visuel
}) => {
  return (
    <div className={classNames('text_graph_two_col')}>
      <TitleBlock
        title={title}
      />
      <div className={classNames('text_visuel')}>
        <div className={classNames('text_container')}>
          <div className="text_center">
            {
              text && (
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              )
            }
            {
              textList && (
                <ul>
                  {
                    textList.map((textItem, index) => (
                      <li key={index}>
                        {textItem.text}
                      </li>
                    ))
                  }
                </ul>
              )
            }
          </div>
        </div>
        {graphDatas && graphDatas.length > 0 && (
          <div className={classNames('visuel')}>
            {
              graphDatas.map((graphData, index) => (
                <div className="graph_view" key={index}>
                  <p className="title">{graphData.title}</p>
                  <HighchartsReact highcharts={Highcharts} options={graphData.datas} />
                </div>
              ))
            }
          </div>
        )}
      </div>
    </div>
  );
};

TextGraphTwoCol.defaultProps = {
  text: 'Default Description Description Description Description Description Description Description',
  alt: '',
};

TextGraphTwoCol.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default TextGraphTwoCol;
