import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../../Layout";
import Seo from "../../../components/Seo/Seo";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageBanner from "../../../components/PageBanner/PageBanner";
import TitlePage from "../../../Molecules/TitlePage/TitlePage";
import IntroPage from "../../../components/ContentType/IntroPage/IntroPage";

import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import TextVisuelTwoCol from "../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol";
import TextGraphTwoCol from "../../../components/ContentType/TextGraphTwoCol/TextGraphTwoCol";
import ShapeBackground from "../../../Molecules/ShapeBackground/ShapeBackground";
import DocumentView from "../../../Molecules/DocumentView/DocumentView";
import ScrollPageNav from "../../../components/ScrollPageNav/ScrollPageNav";

import { getLineChartConfigData, getLineColumnChartConfigData, getMultipleLineChartConfigData } from '../../../Utils/Utils'

var classNames = require("classnames");

import '../engagements.scss';

const EngagementPreuve = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const text_indicateur_no_bg = [
    {
      section: {
        text: "<ul><li><strong>Authenticité</strong> pour couvrir les vraies problématiques des activités du Groupe et qui sont donc reliés aux enjeux identifiés dans notre Politique de Développement Responsable (critère dit de « matérialité »)</li><li><strong>Simplicité :</strong> ces indicateurs doivent pouvoir être simples à calculer dans toutes les entités du Groupe en France comme à l’International</li><li><strong>Fiabilité :</strong> ces indicateurs doivent pouvoir être mesurés avec une précision plus fine que l’amplitude de leur variation annuelle</li><li><strong>Transversalité :</strong> communs à différents types d’activités dans le Groupe</li></ul>",
        title:
          "Les indicateurs sélectionnés pour mesurer les performances répondent aux critères suivants :",
        img: data.file.childImageSharp,
      },
    },
  ];

  const text_indicateure = [
    {
      text: "<ul><li>92 éco-variantes proposées par Colas à ses clients</li><li>33% des engins équipés de télématique embarquée</li><li>9.8 millions de m² de chaussées recyclées en place</li><li>19% d’enrobés tièdes dans la production totale d’enrobés</li><li>44% du CA des carrières et gravières réalisent des actions en faveur de la biodiversité</li><li>56 sites accueillent des ruches</li><li>145 espèces remarquables sont hébergées dans des carrières ou gravières</li><li>Economie de carbone</li><li>Empreinte carbone</li><li>Quantité et taux d’enrobés tièdes et asphalte basse température</li></ul>",
      title: "Bus/Dev",
      img: data.file.childImageSharp,
      graphDatas: [
        {
          title: "Economie de carbone",
          datas: getLineChartConfigData({
            type: "line",
            xAxis_data: ["2013", "2014", "2015", "2016", "2017", "2018"],
            xAxis_title: "Anéees",
            yAxis_format: '{value} MT',
            yAxis_title: "Million de tonnes",
            series_data: [
              {
                name: 'Milliers de tonnes',
                color: '#707070',
                data: [180, 80, 105, 190, 160, 170],
                valueSuffix: 'MT',
                marker_fillColor: "#ffed00",
              }
            ],
            tooltip_format: "<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} MT</strong>",

          })
        },
        {
          title: "Ratio des Activités de production de matériaux dotées d’un outil de gestion environnemental",
          datas: getLineColumnChartConfigData({
            xAxis_data: ["2013", "2014", "2015", "2016", "2017", "2018", "2019"],
            xAxis_title: "Anéees",
            yAxis_data: [
              {
                format: "{value} MT",
                title: "Million de tonnes",
              },
              {
                format: '{value} %',
                title: 'Taux d’enrobés tièdes produits',
                opposite: true
              }
            ],
            series_data: [
              {
                name: 'Taux d’enrobés tièdes produits',
                type: 'column',
                yAxis: 1,
                data: [16, 18, 22, 22, 24, 23, 20],
                tooltip: {
                  valueSuffix: '%'
                },
                color: '#A0C8D8'
              },
              {
                name: 'Million de tonnes',
                type: 'spline',
                data: [6.5, 6.8, 7.1, 7.8, 8.2, 7.8, 7.1],
                tooltip: {
                  valueSuffix: ' MT'
                },
                marker: {
                  enabled: true,
                  symbol: "circle",
                  radius: 7.5,
                  fillColor: "#ffed00",
                  lineColor: "#707070",
                  lineWidth: 1
                },
              }
            ]
          })
        }
      ]
    },
    {
      text: "<ul><li>Top 5 des recycleurs mondiaux</li><li>16% d’enrobés recyclés intégrés dans la production d’enrobés</li><li>800 installations de recyclage</li><li>Lorem ipsum dolor sit amet</li><li>17 millions de tonnes de matériaux recyclés Quantité et taux de recyclage des agrégats d’enrobés</li></ul>",
      title: "Economie circulaire",
      img: data.file.childImageSharp,
      graphDatas: [
        {
          title: "Quantité et taux de recyclage des agrégats d’enrobés",
          datas: getLineColumnChartConfigData({
            xAxis_data: ["2013", "2014", "2015", "2016", "2017", "2018", "2019"],
            xAxis_title: "Anéees",
            yAxis_data: [
              {
                min: 0,
                format: "{value} MT",
                title: "Million de tonnes",
              },
              {
                format: '{value} %',
                title: 'Taux de recyclage',
                opposite: true
              }
            ],
            series_data: [
              {
                name: 'Taux de recyclage',
                type: 'column',
                yAxis: 1,
                data: [14, 13.5, 13, 14, 15, 14.8, 17],
                tooltip: {
                  valueSuffix: '%'
                },
                color: '#A0C8D8'
              },
              {
                name: 'Million de tonnes',
                type: 'spline',
                data: [5.2, 5.2, 5.2, 5.8, 5.9, 6, 6.1],
                tooltip: {
                  valueSuffix: ' MT'
                },
                marker: {
                  enabled: true,
                  symbol: "circle",
                  radius: 7.5,
                  fillColor: "#ffed00",
                  lineColor: "#707070",
                  lineWidth: 1
                },
              }
            ],
          })
        }
      ]
    },
    {
      text: "<ul><li>83% des sites industriels sont dotés d’un outil de gestion des impacts environnementaux</li><li>43% des sites industriels sont dotés d’une structure de dialogue local</li><li>66 % de finisseurs équipés de dispositifs d’aspiration de fumées de bitume</li><li>61% Taux de raboteuses équipées de dispositifs d’aspiration de poussières</li><li>Dialogue local</li><li>Ratio des Activités de production de matériaux dotées d’un outil de gestion environnemental</li></ul>",
      title: "Impact et accessibilité",
      img: data.file.childImageSharp,
      graphDatas: [
        {
          title: 'Dialogue local',
          datas: getLineChartConfigData({
            type: "line",
            xAxis_data: ["2013", "2014", "2015", "2016", "2017", "2018"],
            xAxis_title: "Anéees",
            yAxis_format: '{value} %',
            yAxis_title: "Chiffre d’affaires",
            series_data: [
              {
                name: 'Chiffres d’affaires',
                color: '#707070',
                data: [35, 35, 30, 40, 48, 42],
                valueSuffix: '%',
                marker_fillColor: "#ffed00",
              }
            ],
            tooltip_format: "<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} %</strong>"
          })
        },
        {
          title: 'Ratio des Activités de production de matériaux dotées d’un outil de gestion environnemental',
          datas: getLineChartConfigData({
            type: "line",
            xAxis_data: ["2013", "2014", "2015", "2016", "2017", "2018"],
            xAxis_title: "Anéees",
            yAxis_format: '{value} %',
            yAxis_title: "Chiffres d’affaires",
            yAxis_min: 0,
            series_data: [
              {
                name: 'Chiffre d’Activité Economique de production de matériaux',
                color: '#707070',
                data: [77, 79, 76, 85, 85, 90],
                valueSuffix: '%',
                marker_fillColor: "#ffed00",
              }
            ],
            tooltip_format: "<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} %</strong>"
          })
        }
      ]
    },
    {
      text: "<ul><li>17880 recrutements (yc saisonniers)</li><li>22% de femmes dans l’encadrement, le management et le personnel technique</li><li>63% des collaborateurs ont suivi au moins une formation dans l’année (2019)</li></ul>",
      title: "Excellence managériale",
      img: data.file.childImageSharp,
      graphDatas: []
    },
    {
      text: "<ul><li>6.12 taux de fréquence des accidents du travail</li><li>0.043 taux de fréquence des accidents de la route impliquant un véhicule Colas</li><li>0.44 Taux de gravité  des accidents du travail des collaborateurs</li><li>Sécurité routière</li></ul>",
      title: "Santé / sécurité",
      img: "none",
      graphDatas: [
        {
          title: 'Sécurité routière',
          datas: getMultipleLineChartConfigData({
            type: "line",
            xAxis_data: ["2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
            xAxis_title: "Anéees",
            yAxis_data: [
              {
                title: "Million de tonnes"
              }
            ],
            series_data: [
              {
                name: 'Fréquence',
                color: '#707070',
                data: [180, 80, 105, 190, 160, 170, 180, 80, 105, 190, 160, 170],
                valueSuffix: 'MT',
                fillColor: "#EE7403",
              },
              {
                name: 'Nombre de véhicules',
                color: '#707070',
                data: [30, 50, 90, 45, 90, 120, 30, 50, 90, 45, 90, 120],
                valueSuffix: 'MT',
                fillColor: "#2D4965",
              },
              {
                name: 'Nombre d’accidents avec tiers responsables ou non',
                color: '#707070',
                data: [10, 50, 120, 40, 60, 50, 10, 50, 120, 40, 60, 50],
                valueSuffix: 'MT',
                fillColor: "#FEED00",
              }
            ],
            tooltip_format: "<b>{point.percentage:.1f}</b> <strong>{point.y:,.0f} MT</strong>",

          })
        }
      ]
    },
    {
      text: "<ul><li>6200 collaborateurs ont suivi la formation « Ethique et conformité appliquées »</li><li>5000 collaborateurs ont été formés au serious game « Fair Play »</li></ul>",
      title: "Ethique et conformité",
      img: data.file.childImageSharp,
      graphDatas: []
    },
  ];

  const text_intro =
    "<p>Afin d’évaluer le niveau d’implication du Groupe en regard de ses enjeux de Développement Responsable, le groupe Colas a mis en place depuis plusieurs années, des indicateurs de performance. En effet, pour gérer des actions et des objectifs, il est primordial de disposer d’un instrument de mesure permettant de suivre notre performance et d’évaluer l’efficacité de nos actions par rapport à nos objectifs : c’est tout l’intérêt de ces indicateurs de performance.</p>";


  return (
    <Layout>
      <Seo
        title="Colas History"
        description="Description de la page Colas History"
      />
      <div
        className={classNames("page_template", "page_engagements")}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Le reporting Xfi',
              section: 'intro_page_component'
            },
            {
              title: 'Nos indicateurs',
              section: 'section_our_indicators'
            },
            {
              title: 'Nos publications',
              section: 'section_nos_publications'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "engagement", url: "/engagements" },
                currentPage: { title: "Nos Preuves", url: "/nos-preuves" },
              }}
            />
            <TitlePage color="color_white" title="NOS PREUVES" />
          </div>
        </PageBanner>
        <IntroPage
          customClass="topZIndex"
          title="Le reporting Xfi"
          subtitle="Lorem ipsum dolor sit met"
          visuel={data.file.childImageSharp}
          text={text_intro}
          size="border" //wrapper
        />
        <section className="section_content">
          {text_indicateur_no_bg.map((text_indicateur_no_bg, index) => (
            <div className="wrapper_page_xs" key={index}>
              <TextVisuelTwoCol
                title={text_indicateur_no_bg.section.title}
                orderInverse={true}
                extremeRight={true}
                textFullWidth={true}
                visuel={text_indicateur_no_bg.section.img}
                text={text_indicateur_no_bg.section.text}
                visuelAspectRatioMobile={1.34241245}
                visuelAspectRatioDesktop={1.04107142857}
                visuelMaxHeightDesktop={560}
              />
            </div>
          ))}
        </section>
        <section className={classNames('section_content', 'section_our_indicators')}>
          <ShapeBackground top="left" color="catskill_white" bottom="left">
            <div className="wrapper_page_xs">
              <TitleSection title="Nos indicateurs" type="arrow" />
              {text_indicateure.map((text_indicateure, index) => (
                <div key={index}>
                  <TextGraphTwoCol
                    title={text_indicateure.title}
                    text={text_indicateure.text}
                    graphDatas={text_indicateure.graphDatas}
                  />
                </div>
              ))}
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames('section_content', 'section_nos_publications')}>
          <div className="wrapper_page_xs">
            <TitleSection title="Nos publications" type="arrow" />
          </div>
          <div className="wrapper_page">
            <DocumentView
              data={[
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
              ]}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EngagementPreuve;
